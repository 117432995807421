<template>
  <v-autocomplete
    :items="items"
    v-model="model"
    item-text="value"
    item-value="key"
    :label="options.label"
    :placeholder="options.placeholder"
    :chips="options.chips"
    :hint="options.hint"
  />
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "field",
  props: ["value", "options"],
  data: () => ({
    items: [],
  }),
  async created() {
    this.items = (await this.fetchCollectionItems(this.options.taxonomy_id)).data.data.map((item) => {
      return {
        value: item.title,
        key: item.id,
      }
    });
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCollectionItems: "Fields/fetchFieldTaxonomy",
    }),
  }
}
</script>

<style scoped>

</style>
